import React, { Component } from "react";

class NavBar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark bg-clear mb-3 p-1">
        <div className="container-fluid">
          <img
            alt=""
            src="/SP_Logo.svg"
            width="50"
            height="50"
            className="d-inline-block align-top mx-auto"
          />{" "}
          {/* <a href="#" className="navbar-brand mr-3">
            SoundPromoter
          </a> */}
          {/* <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <a href="#" className="nav-item nav-link active">
                Home
              </a>
              <a href="#" className="nav-item nav-link">
                Services
              </a>
              <a href="#" className="nav-item nav-link">
                About
              </a>
              <a href="#" className="nav-item nav-link">
                Contact
              </a>
            </div>
            <div className="navbar-nav ml-auto">
              <a href="#" className="nav-item nav-link">
                Register
              </a>
              <a href="#" className="nav-item nav-link">
                Login
              </a>
            </div>
          </div> */}
        </div>
      </nav>
    );
  }
}

export default NavBar;

import React, { Component } from "react";
import NavBar from "./components/navBar";
import * as Forms from "./components/forms";
import Form from "./components/form";
import { db } from "./components/firebase";
import "firebase/firestore";
import "./App.css";

class App extends Component {
  state = {
    form: Forms.EmailSignup,
  };

  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div id="bkgImg" className="app-img-bkg">
          <NavBar />
          <main className="container">
            <div className="card-body shadow my-5 " style={{ margin: "auto" }}>
              <div style={{ fontFamily: "Copperplate" }}>
                <h2 className="text-center">
                  Are you an aspiring musician interested in helping yourself
                  and others save time managing their music?
                </h2>
                <h4 className="text-center">Sign up for an interview today!</h4>
              </div>

              <div className="col-md-6 mx-auto">
                <Form
                  form={this.state.form}
                  onValidatedSubmit={this.handleValidatedSubmit}
                />
              </div>
            </div>
          </main>
        </div>
      </React.Fragment>
    );
  }

  handleValidatedSubmit = (items) => {
    switch (this.state.form) {
      case Forms.UserLogin:
        console.log("HandleValidatedUserLogin", items);
        break;
      case Forms.EmailSignup:
        var name,
          email = "";
        for (var i = 0; i < items.length; i++) {
          if (items[i].label === "Name") {
            name = items[i].value;
          } else if (items[i].label === "Email") {
            email = items[i].value;
          }
        }

        this.handleEmailSignup(name, email);
        break;
      default:
        break;
    }
  };

  handleEmailSignup(name, email) {
    const emailDocRef = db.collection("users").doc("emails");

    emailDocRef.get().then((doc) => {
      if (doc.exists) {
        //Read the current email count
        const emailCount = doc.data().count;
        const newEmailCount = emailCount + 1;

        //Merge the newly input email into the document and update the count.
        emailDocRef
          .set(
            {
              count: newEmailCount,
              [name]: email,
            },
            { merge: true }
          )
          .then(() => {
            alert("Thank you for signing up! We will be contacting you soon.");
            // window.location.href = "http://soundpromoter.com/success.html";
          })
          .catch((error) => {
            alert("There was an error signing you up. Please try again.");
          });
      } else {
        console.log("No such document exists.");
      }
    });
  }
}

export default App;

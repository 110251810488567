import FormItem from "./formItem";
import { inputTypes } from "./inputTypes";

export const UserLogin = [
  new FormItem("username", "Username", inputTypes.alphanumeric, "", true, true),
  new FormItem("password", "Password", inputTypes.password, "", true, true),
];

export const EmailSignup = [
  new FormItem("name", "Name", inputTypes.letters, "", true, true),
  new FormItem("email", "Email", inputTypes.email, "", true, true),
];

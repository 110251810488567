import React, { Component } from "react";
import { inputTypes } from "./inputTypes";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { items: this.props.form };
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <ul className="list-unstyled">
          {this.state.items.map((item) => this.renderedFormItem(item))}
          <li>
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary btn-md"
            />
          </li>
        </ul>
      </form>
    );
  }

  renderedFormItem(item) {
    return (
      <li key={item.id}>
        <label style={{ width: "100%" }}>
          {item.label}:
          <input
            id={item.id}
            type={this.inputType(item.type)}
            value={item.value}
            onChange={this.handleChange}
            className="form-control"
            required={item.isRequired}
          />
        </label>
      </li>
    );
  }

  inputType(type) {
    var returnType = "";
    switch (type) {
      case (inputTypes.alphanumeric, inputTypes.letters):
        returnType = "text";
        break;
      case inputTypes.numbers:
        returnType = "number";
        break;
      case inputTypes.email:
        returnType = "email";
        break;
      case inputTypes.password:
        returnType = "password";
        break;
      default:
        returnType = "text";
        break;
    }
    return returnType;
  }

  handleChange = (event) => {
    const items = [...this.state.items];
    const index = this.indexFor(event.target.id);
    if (index !== null) {
      items[index] = {
        id: event.target.id,
        label: items[index].label,
        type: items[index].type,
        value: event.target.value,
        isValid: items[index].isValid,
        isRequired: items[index].isRequired,
      };
      this.setState({ items });
    }
  };

  indexFor(itemId) {
    const items = this.state.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === itemId) {
        return i;
      }
    }
    return null;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    //Validate the input and call the super method.
    const items = [...this.state.items];
    var i = 0;
    var inputsAreValid = true;

    do {
      const item = this.state.items[i];
      switch (item.type) {
        case inputTypes.letters:
          inputsAreValid = this.inputIsLettersOnly(item.value);
          break;
        case inputTypes.alphanumeric:
          inputsAreValid = this.inputIsAlphanumericOnly(item.value);
          break;
        case inputTypes.email:
          inputsAreValid = this.inputIsValidEmail(item.value);
          break;
        default:
          break;
      }

      i++;
    } while (inputsAreValid && i < this.state.items.length);

    if (inputsAreValid) {
      this.props.onValidatedSubmit(this.state.items);
    } else {
      alert("Please enter a valid " + items[i - 1].label);
    }
  };

  inputIsLettersOnly(text) {
    var letters = /^[a-zA-Z ]+$/;
    return text.match(letters);
  }

  inputIsAlphanumericOnly(text) {
    var letters = /^[0-9a-zA-Z ]+$/;
    return text.match(letters);
  }

  inputIsValidEmail(text) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);
  }
}

export default Form;

import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAMkzu0vfkLNzVIQij8cafhAtbCf54XBBs",
  authDomain: "soundpromoter-ace96.firebaseapp.com",
  databaseURL: "https://soundpromoter-ace96.firebaseio.com",
  projectId: "soundpromoter-ace96",
  storageBucket: "soundpromoter-ace96.appspot.com",
  messagingSenderId: "430715675681",
  appId: "1:430715675681:web:fe138d37da483d7cbf2f60",
  measurementId: "G-7M9CGP5WKV",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export { db };

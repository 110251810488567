class FormItem {
  constructor(id, label, type, value, isValid, isRequired) {
    this.id = id;
    this.label = label;
    this.type = type;
    this.value = value;
    this.isValid = isValid;
    this.isRequired = isRequired;
  }
}

export default FormItem;
